<template>
    <div class="TeamCard" title="">
        <section class="TeamCard-picture" onmousedown="return false;">
            <div @click.prevent="cardFlip" @mousemove.stop="cardOver" @mouseout.stop="cardOut" class="TeamCard-over"></div>
            <div :style="spotBackgroundColor" class="TeamCard-picture_spot"></div>
            <div class="TeamCard-picture_front" ref="front">
                <img :src="foxPictureFile" alt="" />
            </div>
            <div class="TeamCard-picture_back" ref="back">
                <img :src="realPictureFile" alt="" />
            </div>
        </section>
        <div class="TeamCard-info">
            <p :class="[{'TeamCard-info_name_compact':compact}]" class="TeamCard-info_name">{{firstName}} {{lastName}}</p>
            <p class="TeamCard-info_job" v-if="job"><b>{{job}}</b></p>
            <p class="TeamCard-info_wantedFor" v-if="wantedFor">Wanted for: <b>{{wantedFor}}</b></p>
        </div>
    </div>
</template>

<script>
    import { TweenMax, CSSPlugin, Elastic } from 'gsap';
    // @ is an alias to /src

    CSSPlugin.defaultTransformPerspective = 800;

    export default {

        name: 'TeamCard',
        props: {
            firstName: String,
            lastName: String,
            job: String,
            wantedFor: String,
            foxPicture: String,
            realPicture: String,
            color: String
        },
        data() {
            return {
                publicPath: process.env.BASE_URL,
                isRecto: true,
                iLastDeg: 0,
                iDeg: 180,
                isFlipping: false,
                twFlipFront: null,
                twFlipBack: null,
                twSpot: null,
                flipSpeed: .3,
                elasticSpeed: 1,
                spotSpeed: .8
            };
        },
        computed: {
            compact() {
                if (this.firstName === undefined || this.lastName === undefined) {
                    return false;
                }
                return this.firstName.concat(this.lastName).length > 15;
            },
            foxPictureFile() {
                if (this.foxPicture) {
                    return this.foxPicture;
                }
                return `${process.env.BASE_URL}img/foxes/${this.normalizedName()}_fox.png`;
            },
            realPictureFile() {
                if (this.realPicture) {
                    return this.realPicture;
                }
                return `${process.env.BASE_URL}img/foxes/${this.normalizedName()}_real.png`;
            },
            spotBackgroundColor() {
                return `background-color:${this.color}`;
            }
        },
        methods: {
            normalizedName() {
                return `${this.firstName}${this.lastName}`.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            },
            cardSide(event, side) {
                return event.target.parentElement.querySelector('.TeamCard-picture_' + side);
            },
            carOverPos: function (event) {
                return parseInt((event.offsetX / event.target.clientWidth) * 100 - 50);
            },
            cardOver: function (event) {
                var iCardPos = this.carOverPos(event);
                if (!this.isFlipping) {
                    this.twFlipFront = TweenMax.to(this.cardSide(event, 'front'), this.elasticSpeed, { rotationY: this.iLastDeg + (iCardPos * .5) });
                    this.twFlipBack = TweenMax.to(this.cardSide(event, 'back'), this.elasticSpeed, { rotationY: this.iLastDeg + (iCardPos * .5) + this.iDeg });
                }
            },
            cardOut: function (event) {
                this.isFlipping = false;
                this.twFlipFront = TweenMax.to(this.cardSide(event, 'front'), this.elasticSpeed, { rotationY: this.iLastDeg, ease: Elastic.easeOut });
                this.twFlipBack = TweenMax.to(this.cardSide(event, 'back'), this.elasticSpeed, {
                    rotationY: this.iLastDeg + this.iDeg,
                    ease: Elastic.easeOut
                });
            },
            cardFlip: function (event) {
                this.isFlipping = true;
                var iCardPos = this.carOverPos(event);
                this.iLastDeg = this.iLastDeg + ((iCardPos < 0) ? -this.iDeg : +this.iDeg);
                this.twFlipFront = TweenMax.to(this.cardSide(event, 'front'), this.flipSpeed, {
                    rotationY: this.iLastDeg,
                    onComplete: !this.isFlipping
                });
                this.twFlipBack = TweenMax.to(this.cardSide(event, 'back'), this.flipSpeed, {
                    rotationY: this.iLastDeg + this.iDeg,
                    onComplete: !this.isFlipping
                });
            }
        }
    };
</script>
